import { defineStore } from 'pinia'
import { useCookies } from '@vueuse/integrations/useCookies'
import LWPPInstanceDisconnectedWarning from '@/Components/LWPPInstanceDisconnectedWarning.vue'

export const useLegacyWhatsAppInstancesStore = defineStore(
  'legacy-whatsapp-instances-store',
  {
    state: (): IWhatsAppInstance => ({
      instances: [],
      timeout: null,
      refreshing: false,
    }),
    getters: {
      hasDisconnectedInstances: (state) => {
        return state.instances.some(
          (instance) => instance.status === 'disconnected',
        )
      },
      hasInstance: (state) => {
        return (id: number) =>
          state.instances.some((instance) => instance.id === id)
      },
      getDisconnectedInstances: (state) => {
        return state.instances.filter(
          (instance) => instance.status === 'disconnected',
        )
      },
      getInstances: (state) => state.instances,
      getInstanceById: (state) => {
        return (id: number): IWhatsAppInstance['instances'][0] | undefined =>
          state.instances.find((instance) => instance.id === id)
      },
      hasManyInstances: (state) => state.instances.length > 1,
      isDisconnected: (state) => {
        return (id: number) =>
          state.instances.some(
            (instance) =>
              instance.id === id && instance.status === 'disconnected',
          )
      },
    },
    actions: {
      async fetchInstances(props?: { makeCron?: boolean }) {
        const $fetch = async () => {
          this.refreshing = true
          try {
            const { data } = await window.axios.get<IFetchInstancesResponse>(
              route('api.system.instance.index'),
            )

            this.instances = data.instances

            if (this.hasDisconnectedInstances) {
              const parsedInstances = this.getDisconnectedInstances.map(
                (element) => {
                  return {
                    ...element,
                    url: route('dashboard.v2.integration.wpp.show', {
                      instance: element.id,
                    }),
                  }
                },
              )
              this.renderDisconnectedComponent(parsedInstances)
            }

            return true
          } catch (error) {
            console.error('Erro ao buscar instâncias:', error)
            return false
          } finally {
            setTimeout(() => {
              this.refreshing = false
            }, 2000)
          }
        }

        const response = await $fetch()

        if (props?.makeCron) {
          if (response) {
            this.timeout = setTimeout(
              () => this.fetchInstances({ makeCron: true }),
              60_000,
            )
          }
        }
      },
      async forceRefresh() {
        clearTimeout(this.timeout as NodeJS.Timeout)
        this.fetchInstances({ makeCron: true })
      },
      renderDisconnectedComponent(instances: IWhatsAppInstance['instances']) {
        const existingModal = document.getElementById('disconnected-alert')

        // Se já existir, remove antes de recriar
        if (existingModal) {
          existingModal.remove()
        }

        const { get } = useCookies()

        const warningCookie = get('silence_whatsapp_warning')
        if (warningCookie && warningCookie === true) {
          return
        }

        // Cria um container no body para renderizar o componente
        const container = document.createElement('div')
        container.id = 'disconnected-alert'
        document.body.appendChild(container)

        // Monta o componente dinamicamente
        createApp(LWPPInstanceDisconnectedWarning, { instances }).mount(
          container,
        )
      },
    },
  },
)

interface IWhatsAppInstance {
  instances: {
    id: number
    name: string
    status: string
    external_id: string
  }[]
  timeout: NodeJS.Timeout | null
  refreshing: boolean
}

interface IFetchInstancesResponse {
  instances: IWhatsAppInstance['instances']
}
