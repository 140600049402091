<script setup lang="ts">
const useAuth = useAuthStore()
</script>
<template>
  <div class="tw-daisy-dropdown tw-daisy-dropdown-end">
    <div
      tabindex="0"
      role="button"
      class="tw-daisy-btn tw-daisy-btn-ghost tw-daisy-btn-circle tw-daisy-avatar tw-m-1"
    >
      <div class="tw-w-10 tw-rounded-full">
        <Avatar :src="useAuth.avatar" />
      </div>
    </div>
    <ul
      tabindex="0"
      class="tw-daisy-menu tw-daisy-menu-sm tw-daisy-dropdown-content tw-bg-base-100 tw-rounded-box tw-z-[1] tw-mt-3 tw-w-52 tw-p-2 tw-shadow"
    >
      <li>
        <Link :href="route('dashboard.v2.profile.index')"> Perfil </Link>
      </li>
      <li>
        <Link :href="route('dashboard.v2.profile.api.token.index')">
          Token de API
        </Link>
      </li>
      <!-- <li>
        <Link :href="route('dashboard.v2.profile.settings')">
          Configurações
        </Link>
      </li> -->
      <li>
        <Link href="/logout" method="post" as="button" type="button">
          Sair
        </Link>
      </li>
    </ul>
  </div>
</template>
