<script setup lang="ts">
const legacyWhatsAppInstances = useLegacyWhatsAppInstancesStore()

onMounted(() => {
  legacyWhatsAppInstances.fetchInstances({ makeCron: true })
})
</script>
<template>
  <slot />
</template>
