<script setup lang="ts">
import Avatar from '@/../assets/images/avatar.webp'

const props = withDefaults(
  defineProps<{
    src?: string | null
    ratio?: number
    lazy?: boolean
    showProvider?: boolean
  }>(),
  {
    lazy: true,
  },
)

const srcImage = computed(() => {
  if (!props.src) {
    return Avatar
  }

  return props.src
})

const handleImgError = (event: Event) => {
  const target = event.target as HTMLImageElement
  target.src = Avatar
}
</script>
<template>
  <img
    :src="srcImage"
    :loading="lazy === false ? 'eager' : 'lazy'"
    @error="(event) => handleImgError(event)"
  />
</template>
