<script setup lang="ts">
import IPChatAuthBoxLeft from '@/../assets/images/ipchat-auth-box-left.webp'
import { usePage } from '@inertiajs/vue3'
import { StyleValue } from 'vue'

const authProps = computed(() => usePage().props.auth)

const boxLeftStyles = computed(() => {
  const styles: StyleValue = {}

  if (authProps.value.agency?.customization?.pages?.auth?.boxLeft?.bgImage) {
    styles['background-image'] =
      `url('${authProps.value.agency?.customization?.pages?.auth?.boxLeft?.bgImage}')`
  } else {
    styles['background-image'] = `url('${IPChatAuthBoxLeft}')`
  }

  return styles
})
</script>
<template>
  <section class="tw-w-full tw-flex tw-flex-col tw-min-h-screen">
    <section class="tw-flex tw-flex-1 tw-w-full">
      <section class="banner-block" :style="boxLeftStyles" />

      <section
        class="tw-flex tw-w-full min-[1080px]:tw-w-[550px] tw-bg-white tw-items-center tw-justify-center"
      >
        <section class="login-box tw-w-[550px] min-[1080px]:tw-w-full">
          <div
            v-if="authProps.agency?.logo"
            class="tw-flex tw-w-full tw-justify-center tw-mb-10"
          >
            <img
              :src="authProps.agency?.logo"
              class="tw-max-w-[60%]"
              loading="lazy"
            />
          </div>

          <slot />
        </section>
      </section>
    </section>
  </section>
</template>

<style scoped>
.banner-block {
  @apply tw-hidden min-[1080px]:tw-flex tw-w-[calc(100%_-_550px)] tw-bg-white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.login-box {
  @apply tw-flex tw-flex-wrap tw-px-10;
}

.login-box label {
  @apply tw-w-full tw-mb-3 tw-flex tw-flex-wrap;
}

.input-wrapper {
  @apply tw-flex tw-w-full tw-flex-col tw-rounded-md tw-border-[1px] tw-border-slate-200 tw-transition-[border] tw-duration-[.2s] tw-ease-in-out tw-bg-white;
}
.inputItem .label {
  @apply tw-flex tw-w-max tw-text-[.9rem] tw-text-slate-500 tw-absolute tw-top-[50%] tw-translate-y-[-50%] tw-left-0 tw-z-30 tw-pointer-events-none tw-px-4;
  transition:
    transform 0.1s ease-out,
    font-size 0.1s ease-out,
    left 0.1s ease-out,
    color 0.1s ease-out;
}

.inputItem:focus-within .label,
.inputItem .label.noEmpty {
  @apply tw-left-2 tw-translate-y-[-35px] tw-text-[.8rem];
}

.inputItem:focus-within .label p:before,
.inputItem .label.noEmpty p:before {
  @apply tw-content-[''] tw-absolute tw-top-[calc(20%)] tw-left-0 tw-h-[10px] tw-bg-white tw-w-full -tw-z-10 tw-origin-center;
}
</style>
