import './bootstrap'
import '../css/globals.css'
import '../css/app.css'
import '../css/chat-globals.css'

import '@hdmasters/vue-toast-notification/dist/theme-sugar.css'

import { createApp, h, DefineComponent } from 'vue'
import { createPinia } from 'pinia'
import { Link, createInertiaApp, Head, router } from '@inertiajs/vue3'
import { i18n } from './i18n'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { flare } from '@flareapp/js'
import { flareVue } from '@flareapp/vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { Quasar, Notify, Dialog, Meta } from 'quasar'

// Plugins
import FormatTime from './Plugins/FormatTime'
import FormatDate from './Plugins/FormatDate'
import ScrollToBottom from './Plugins/ScrollToBottom'
import VueChatScroll from 'vue3-chat-scroll'

// Layouts
import DashboardLayout from './Layouts/Dashboard/DashboardLayout.vue'
import AuthLayout from './Layouts/Auth/AuthLayout.vue'

// Providers
import SocketProviderV2 from './Providers/SocketProviderV2.vue'
import AuthWrapper from './Providers/AuthWrapper.vue'
import AgencyWrapper from './Providers/AgencyWrapper.vue'
import Channels from './Providers/Channels.vue'
import LoadConversations from './Providers/LoadConversations.vue'
import InstancesConnection from './Providers/InstancesConnection.vue'

const appName = window.document.getElementsByTagName('title')[0]?.innerText

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

router.on('navigate', () => {
  const flashMessage = useFlashMessages()
  flashMessage.resetNotice()
})

if (import.meta.env.VITE_APP_ENV === 'production') {
  //flare.light()
}

createInertiaApp({
  progress: {
    delay: 250,
    color: '#29d',
    includeCSS: true,
    showSpinner: false,
  },
  title: (title) => `${title ? `${title} - ` : ''}${appName}`,
  resolve: (name) => {
    const page = resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob<DefineComponent>('./Pages/**/*.vue'),
    )

    if (name.startsWith('Dashboard/')) {
      page.then((module) => {
        module.default.layout = module.default.layout || [
          AuthWrapper,
          Channels,
          SocketProviderV2,
          LoadConversations,
          InstancesConnection,
          DashboardLayout,
        ]
      })
    } else if (name.startsWith('Chat/')) {
      page.then((module) => {
        module.default.layout = module.default.layout || [
          AuthWrapper,
          Channels,
          SocketProviderV2,
          LoadConversations,
          InstancesConnection,
        ]
      })
    } else if (name.startsWith('Auth/')) {
      page.then((module) => {
        module.default.layout = module.default.layout || [
          //AgencyWrapper,
          AuthLayout,
        ]
      })
    }

    return page
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({
      render: () => h(App, props),
    })

    app
      .use(Quasar, {
        plugins: {
          Meta,
          Notify,
          Dialog,
        },
      })
      .use(plugin)
      .use(flareVue)
      .use(pinia)
      .use(i18n)
      .use(FormatTime)
      .use(FormatDate)
      .use(ScrollToBottom)
      .use(VueChatScroll)
      .use(ZiggyVue)
      .component('HeadPage', Head)
      .component('Link', Link)
      .mount(el)

    return app
  },
})
