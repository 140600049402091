<script setup lang="ts">
import { BellSnoozeIcon } from '@heroicons/vue/24/outline'
import { useCookies } from '@vueuse/integrations/useCookies'
import { Link } from '@inertiajs/vue3'

defineProps<{
  instances: {
    id: number
    name: string
    status: string
    external_id: string
    url: string
  }[]
}>()

const cookies = useCookies(['silence_whatsapp_warning'])
const useAuth = useAuthStore()
const isVisible = ref(true)

function silenceForOneDay() {
  cookies.set('silence_whatsapp_warning', true, {
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
  })

  closeModal()
}

const closeModal = () => {
  isVisible.value = false
}
</script>

<template>
  <div
    v-if="isVisible"
    class="tw-fixed tw-bottom-4 tw-right-4 tw-bg-red-600 dark:tw-bg-red-700 tw-text-white tw-p-4 tw-rounded-lg tw-shadow-lg tw-w-90 tw-max-h-80 tw-flex tw-flex-col tw-gap-2 tw-z-[9999]"
  >
    <div class="tw-flex tw-justify-between tw-items-center">
      <strong>🚨 WhatsApp desconectado!</strong>
      <button @click="closeModal" class="tw-text-white tw-text-lg tw-font-bold">
        &times;
      </button>
    </div>
    <p>Os WhatsApps abaixo estão desconectados:</p>
    <div class="tw-daisy-divider tw-my-1"></div>
    <ul class="tw-max-h-40 tw-overflow-y-auto">
      <li v-for="instance in instances" :key="instance.id" class="tw-mb-1">
        <div class="tw-flex tw-items-center tw-justify-between">
          <span>- {{ instance.name ?? instance.external_id }}</span>
          <Link
            v-if="useAuth.isAdmin"
            :href="instance.url"
            class="tw-daisy-btn tw-daisy-btn-xs"
          >
            Conectar
          </Link>
        </div>
      </li>
    </ul>
    <div class="tw-daisy-divider tw-my-1"></div>
    <button
      class="tw-daisy-btn tw-daisy-btn-block tw-daisy-btn-xs"
      @click="silenceForOneDay"
    >
      <BellSnoozeIcon class="tw-size-4 icon" />
      Silenciar por 1 dia
    </button>
  </div>
</template>
