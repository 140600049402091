<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
const authProps = computed(() => usePage().props.auth)
const authStore = useAuthStore()

onBeforeMount(() => {
  const userId = authProps.value.user.id
  const companyId = authProps.value.company.id

  nextTick(() => {
    if (!userId && !companyId) {
      return
    }

    window.UserSocket = window.Echo.private(`user.${userId}`)
    window.ChatSocket = window.Echo.private(`v2-chat.${companyId}.${userId}`)
    window.GroupSocket = window.Echo.private(`v2-group.${companyId}`)
    window.CompanySocket = window.Echo.join(`v2-company.${companyId}`)

    if (authStore.isObserver) {
      window.ObserverSocket = window.Echo.private(`v2-observer.${companyId}`)
    }
  })
})
</script>
<template>
  <slot />
</template>
